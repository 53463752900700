import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from "../views/Home.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/live',
    name: 'Live',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/live.vue')
  },
  {
    path: '/download',
    name: 'Download',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/download.vue')
  },
  {
    path: '/add',
    name: 'Add',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/add.vue')
  },
  {
    path: '/skippage',
    name: 'Skippage',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/Skippage.vue')
  },
  {
    path: '/file',
    name: 'File',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/file/file.vue')
  },
  {
    path: '/more',
    name: 'More',
    meta: {
      title: '禾乡农场'
    },  
    component: () => import('../views/file/more.vue')
  },
  {
    path: '/agree',
    name: 'Agree',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/agree.vue')
  },
  {
    path: '/friend',
    name: 'Friend',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/friend.vue')
  },
  {
    path: '/print',
    name: 'Print',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/print.vue')
  },
  {
    path: '/mch',
    name: 'Mch',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/mchDownload.vue')
  },

  {
    path: '/recomm',
    name: 'recomm',
    meta: {
      title: '隐私政策'
    },
    component: () => import('../views/protocol/recomm.vue')
  },
  {
    path: '/protocol',
    name: 'protocol',
    meta: {
      title: '隐私政策' 
    },
    component: () => import('../views/protocol/protocol.vue')
  },
  {
    path: '/downloadshare',
    name: 'downloadshare',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/downloadshare.vue')
  },
  {
    path: '/playground',
    name: 'playground',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/playground.vue')
  },
  {
    path: '/dynamic/info',
    name: 'webpass',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/Webpass.vue')
  },
  {
    path: '/public/pig_info',
    name: 'piginfo',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/PigiFo.vue')
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/index.vue')
  },
  {
    path: '/',
    name: 'friend',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/friend.vue')
  },
  {
    path: '/drawhome',
    name: 'drawhome',
    meta: {
      title: '禾乡农场抽奖'
    },
    component: () => import('../views/draw/drawhome')
  },
  {
    path: '/drawpig',
    name: 'drawpig',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/draw/drawpig')
  },
  {
    path: '/drawpignew',
    name: 'drawpignew',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/draw/drawpignew')
  },
  {
    path: '/drawpigoffice',
    name: 'drawpigoffice',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/draw/drawpigoffice')
  },
  {
    path: '/drawpigresi',
    name: 'drawpigresi',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/draw/drawpigresi')
  },
  {
    path: '/drawpigone',
    name: 'drawpigone',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/draw/drawpigone')
  },
  {
    path: '/diraw',
    name: 'diraw',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/draw/diraw')
  },
  {
    path: '/drawpigtwo',
    name: 'drawpigtwo',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/draw/drawpigtwo')
  },
  {
    path: '/drawpigplay',
    name: 'drawpigplay',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/draw/drawpigplay')
  },
  {
    path: '/porkling',
    name: 'porkling',
    meta: {
      title: '禾乡农场'
    },
    component: () => import('../views/draw/porkling')
  },


  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
