<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title" />
  </div>
</template>

<script>
export default {
};
</script>
<style lang="scss">
p {
  margin: 0;
  padding: 0;
}

</style>
