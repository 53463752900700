import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    p_id: ''
  },
  mutations: {
    SET_P_ID(state, val) {
      state.p_id = val
    }
  },
  actions: {},
  modules: {}
})
